import {User, UserPagination} from "../../modules/admin/users/users.type";
import {Injectable} from "@angular/core";
import {BehaviorSubject, filter, map, Observable, of, switchMap, take, tap, throwError} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";


@Injectable({providedIn: 'root'})
export class UserService {
    private path = 'users';
    private _user: BehaviorSubject<User | null> = new BehaviorSubject(null);
    private _users: BehaviorSubject<User[] | null> = new BehaviorSubject(null);
    private _pagination: BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(private _httpClient: HttpClient) {
    }

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User) {
        // Store the value
        this._user.next(value);
    }

    get users$(): Observable<User[]> {
        return this._users.asObservable();
    }

    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    get pagination$(): Observable<any> {
        return this._pagination.asObservable();
    }

    getUsers(page: number = 1, size: number = 10, sort: string = 'name', order: 'asc' | 'desc' | '' = 'asc', search: string = ''): Observable<User[]> {
        return this._httpClient.get<{ pagination: UserPagination; users: User[] }>(environment.api + this.path, {
            params: {
                page: '' + page,
                limit: size,
                sort,
                order,
                search,
            }
        }).pipe(
            tap((response: any) => {
                this._pagination.next(response.pagination);
                this._users.next(response.users);
            }),
        );
    }

    getUsersByCompanyId(companyId: string): Observable<User[]> {
        return this._httpClient.get<{users: User[] }>(environment.api + this.path + '/company/' + companyId).pipe(
            tap((response: any) => {
                //this._pagination.next(response.pagination);
                this._users.next(response.users);
            }),
        );
    }

    getUserById(id: string): Observable<User> {
        console.log('getUserById', id);
        console.log('URL : ');
        console.log(environment.api + this.path + '/' + id);
        return this._httpClient.get<User>(environment.api + this.path + '/' + id).pipe(
            map((user) => {
                // Update the course
                this._user.next(user);

                // Return the course
                return user;
            }),
            switchMap((user) => {
                if (!user) {
                    return throwError('Could not found user with id of ' + id + '!');
                }

                return of(user);
            }),
        );
    }

    delete(id: string): Observable<boolean> {
        return this.users$.pipe(
            take(1),
            switchMap(users => this._httpClient.delete(environment.api + this.path, {params: {id}}).pipe(
                map((isDeleted: boolean) => {
                    // Find the index of the deleted contact
                    const index = users.findIndex(item => item._id === id);

                    // Delete the contact
                    users.splice(index, 1);

                    // Update the contacts
                    this._users.next(users);

                    // Return the deleted status
                    return isDeleted;
                }),
            )),
        );
    }

    uploadAvatar(id: string, profile_image: File): Observable<User> {
        console.log('Upload Avatar');
        return this.users$.pipe(
            take(1),
            switchMap(users => this._httpClient.post<User>(environment.api + this.path + '/profile_image', {
                id,
                profile_image,
            }, {
                headers: {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'Content-Type': profile_image.type,
                },
            }).pipe(
                map((updatedUser) => {
                    // Find the index of the updated contact
                    const index = users.findIndex(item => item._id === id);

                    // Update the contact
                    users[index] = updatedUser;

                    // Update the contacts
                    this._users.next(users);

                    // Return the updated contact
                    return updatedUser;
                }),
                switchMap(updatedUser => this.user$.pipe(
                    take(1),
                    filter(item => item && item._id === id),
                    tap(() => {
                        // Update the contact if it's selected
                        this._user.next(updatedUser);

                        // Return the updated contact
                        return updatedUser;
                    }),
                )),
            )),
        );
    }

    update(id: string, user: User): Observable<User> {
        return this.users$.pipe(
            take(1),
            switchMap(users => this._httpClient.patch<User>(environment.api + this.path + '/' + id, {
                user,
            }).pipe(
                map((updatedUser) => {
                    // Find the index of the updated contact
                    const index = users.findIndex(item => item._id === id);

                    // Update the contact
                    users[index] = updatedUser;

                    // Update the contacts
                    this._users.next(users);

                    // Return the updated contact
                    return updatedUser;
                }),
                switchMap(updatedUser => this.user$.pipe(
                    take(1),
                    filter(item => item && item._id === id),
                    tap(() => {
                        // Update the contact if it's selected
                        this._user.next(updatedUser);

                        // Return the updated contact
                        return updatedUser;
                    }),
                )),
            )),
        );
    }
}
