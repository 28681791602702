import {Route, Routes} from '@angular/router';
import {initialDataResolver} from 'app/app.resolvers';
import {AuthGuard} from 'app/core/auth/guards/auth.guard';
import {NoAuthGuard} from 'app/core/auth/guards/noAuth.guard';
import {LayoutComponent} from 'app/layout/layout.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    {path: '', pathMatch : 'full', redirectTo: 'dashboards'},
    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'dashboards'},

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routes')},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes')},
            {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes')},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes')},
            {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes')}
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes')},
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes')}
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            {path: 'dashboards', children: [
                {path: 'project', loadChildren: () => import('app/modules/admin/dashboards/project/project.routes')},
            ]},
            {path: 'companies', children: [
                {path: '', loadChildren: () => import('app/modules/admin/companies/companies.routes')},
            ]},
            {path: 'offers', children: [
                {path: '', loadChildren: () => import('app/modules/admin/offers/offers.routes')},
            ]},
            {path: 'services', children: [
                    {path: '', loadChildren: () => import('app/modules/admin/services/services.routes')},
            ]},
            {path: 'suggestions', children: [
                    {path: '', loadChildren: () => import('app/modules/admin/suggestions/suggestions.routes')},
            ]},
            {path: 'news', children: [
                    {path: '', loadChildren: () => import('app/modules/admin/news/news.routes')},
            ]},
            {path: 'users', children: [
                    {path: '', loadChildren: () => import('app/modules/admin/users/users.routes')},
            ]},
            {path: 'payments', children: [
                {path: '', loadChildren: ()=> import('app/modules/admin/payments/orders/orders.routes')}
            ]},
            {
                path: 'front-app', children: [
                    {path: '', loadChildren: () => import('app/modules/admin/front-app/front-app.routes')}
                    ]
            },
            {
                path: 'subscriptions', children: [
                    {path: '', loadChildren: () => import('app/modules/admin/subscriptions/subscriptions.routes')}
                ]
            }
        ]
    }
] as Routes;
