<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Empty layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<empty-layout *ngIf="layout === 'empty'"></empty-layout>

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Layouts with horizontal navigation -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<!--&lt;!&ndash; Centered &ndash;&gt;-->
<!--<centered-layout *ngIf="layout === 'centered'"></centered-layout>-->

<!--&lt;!&ndash; Enterprise &ndash;&gt;-->
<!--<enterprise-layout *ngIf="layout === 'enterprise'"></enterprise-layout>-->

<!--&lt;!&ndash; Material &ndash;&gt;-->
<!--<material-layout *ngIf="layout === 'material'"></material-layout>-->

<!--&lt;!&ndash; Modern &ndash;&gt;-->
<!--<modern-layout *ngIf="layout === 'modern'"></modern-layout>-->

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Layouts with vertical navigation -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- Classic -->
<classic-layout *ngIf="layout === 'classic'"></classic-layout>

<!--&lt;!&ndash; Classy &ndash;&gt;-->
<!--<classy-layout *ngIf="layout === 'classy'"></classy-layout>-->

<!--&lt;!&ndash; Compact &ndash;&gt;-->
<!--<compact-layout *ngIf="layout === 'compact'"></compact-layout>-->

<!--&lt;!&ndash; Dense &ndash;&gt;-->
<!--<dense-layout *ngIf="layout === 'dense'"></dense-layout>-->

<!--&lt;!&ndash; Futuristic &ndash;&gt;-->
<!--<futuristic-layout *ngIf="layout === 'futuristic'"></futuristic-layout>-->

<!--&lt;!&ndash; Thin &ndash;&gt;-->
<!--<thin-layout *ngIf="layout === 'thin'"></thin-layout>-->

